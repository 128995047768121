<template>
  <footer class="footer-section theme-footer"
          style="position: fixed; left: 0; bottom: 0;">
    <div class="footer-section-1  sidebar-theme"></div>
    <div class="footer-section-2 container-fluid">
        <div class="row">
            <div id="toggle-grid"
                 style="padding-left: 275px"
                 class="col-xl-7 col-md-6 col-sm-6 col-12">
              <ul class="list-inline mb-0">
                <li class="list-inline-item mr-3"
                    @click="$refs.versionLogModal.show()">
                  <p class="bottom-footer" v-if="versionLogs.length"> Version {{ versionLogs.length ? versionLogs[0].version : '' }}</p>
                </li>
              </ul>
            </div>
            <div class="col-xl-5 col-md-6 col-sm-6 col-12">
                <ul class="list-inline mb-0 d-flex justify-content-sm-end justify-content-center mr-sm-3 ml-sm-0 mx-3">
                  <li class="list-inline-item  mr-3">
                    <p class="bottom-footer">
                      <a href="https://docs.google.com/document/d/e/2PACX-1vS0bZnabuyEYcuOWyrXiGnzjHt10pyun9KraFa5dovoKvt3X42f3UB92hJtggIMog/pub"
                         target="_blank">Privacy Policy</a>
                    </p>
                  </li>
                    <li class="list-inline-item  mr-3">
                        <p class="bottom-footer"> &copy; 2024 Attribution Insights <span class="text-muted d-none d-sm-inline-block float-end"></span></p>
                    </li>
                    <li class="list-inline-item align-self-center">
                        <scroll-top></scroll-top>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </footer>

  <version-log-modal ref="versionLogModal" />
</template>


<script>
import ScrollTop from "@/components/ScrollTop";
import {mapGetters} from "vuex";
import VersionLogModal from "@/components/VersionLogModal.vue";

export default {
  components: {ScrollTop, VersionLogModal},
  computed: {
    ...mapGetters({
      versionLogs: 'getVersionLogs'
    })
  },
  created() {
    this.$store.dispatch('loadVersionLogs');
  },
}
</script>