<template>
  <div v-if="!selectedPackage">
    <div>
      <p class="text" style="width: 85%">
        Every plan comes with 14 days free trial (your card will not be charged until then). Cancel any time.
      </p>
      <p class="text">Choose your plan:</p>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <span class="fs-20 mr-3 text-white"
              style="position: relative; top: -10px">Pay Annually</span>

        <label class="switch s-secondary mt-4">

          <input type="checkbox" checked="" v-model="monthly">
          <span class="slider round" style="background-color: #805dca"></span>
        </label>

        <span class="fs-20 ml-3 text-white"
              style="position: relative; top: -10px">Pay Monthly</span>
      </div>
    </div>


    <section class="pricing-section bg-7">
      <div class="pricing pricing--norbu">
        <div class="pricing__item"
             v-for="card in packages.filter(p => p.monthly === monthly)"
             :key="card.id">
          <h3 class="pricing__title">{{ card.name }}</h3>
          <div class="pricing__price"><span class="pricing__currency">$</span>{{ card.price }}<span class="pricing__period"> /&nbsp; {{ card.period }}</span></div>
<!--          <h4 class="pricing__original_price" v-html="card.original_price"></h4>-->
          <ul class="pricing__feature-list text-center">
            <li class="pricing__feature">14 days free trial included</li>
          </ul>
          <button class="pricing__action mx-auto mb-4"
                  :disabled="loading"
                  @click="createPaymentLink(card)">Start Free Trial</button>
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <Payment @changePlan="selectedPackage = null"
             @goNext="$emit('goNext')"
             :selectedPackage="selectedPackage" />
  </div>
</template>

<script>
import Payment from "./pay/Payment.vue";
export default {
  components: {Payment},
  data() {
    return {
      selectedPackage: null,
      monthly: true,
      packages: [
        {
          id: 1,
          name: 'Collab',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_COLLAB_MONTHLY_PRICE_ID,
          price: 35
        },
        {
          id: 2,
          name: 'Full',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_MONTHLY_PRICE_ID,
          price: 97
        },
        {
          id: 3,
          name: 'Collab',
          period: 'Yearly',
          monthly: false,
          price_id: process.env.VUE_APP_STRIPE_COLLAB_YEARLY_PRICE_ID,
          price: 385
        },
        {
          id: 4,
          name: 'Full',
          period: 'Yearly',
          monthly: false,
          price_id: process.env.VUE_APP_STRIPE_YEARLY_PRICE_ID,
          price: 1067
        }
      ]
      /*packages: [
        {
          id: 1,
          plan: "Monthly",
          price_id: process.env.VUE_APP_STRIPE_MONTHLY_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_MONTHLY_PRODUCT_ID,
          price: 97,
          period: 'month',
          original_price: '&nbsp;',
        },
        {
          id: 2,
          plan: "Bi-annually",
          price_id: process.env.VUE_APP_STRIPE_SIX_MONTH_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_SIX_MONTH_PRODUCT_ID,
          price: 497,
          period: '6 months',
          original_price: '(save of $85)',
        },
        {
          id: 3,
          plan: "Yearly",
          price_id: process.env.VUE_APP_STRIPE_YEARLY_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_YEARLY_PRODUCT_ID,
          price: 897,
          period: 'year',
          original_price: '(save of $267)',
        }
      ]*/
    }
  },
  methods: {
    createPaymentLink(pack) {
      this.loading = true;

      this.axios.post('/payment-link', {
          price_id: pack.price_id,
        })
        .then((res) => {
          window.location.href = res.data.data.url;
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
@import "@/assets/scss/pricing.scss";
</style>
