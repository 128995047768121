<template>
  <Teleport to="body" class="default-sidebar">
    <template v-if="$route.name !== 'login' && $route.name !== 'reset' && user">

      <!--  BEGIN TOPBAR  -->
      <top-bar-component></top-bar-component>
      <!--  END TOPBAR  -->

      <!--  BEGIN MAIN CONTAINER  -->
      <div class="main-container" id="container">

        <div class="overlay"></div>
        <div class="cs-overlay"></div>

        <!--  BEGIN SIDEBAR  -->
        <side-nav-component @showWelcomeModal="showWelcomeModal" />
        <!--  END SIDEBAR  -->

        <!--  BEGIN CONTENT PART  -->
        <div id="content" class="main-content">
          <div class="container">
            <router-view/>
          </div>
        </div>
        <!--  END CONTENT PART  -->
      </div>
      <!-- END MAIN CONTAINER -->



      <!--  BEGIN FOOTER  -->
      <footer-component></footer-component>
      <!--  END FOOTER  -->
    </template>

    <router-view v-else></router-view>
  </Teleport>

  <welcome-modal ref="welcomeModal" />
  <app-loading ref="appLoading" />
</template>

<script>
import TopBarComponent from "@/components/TopBarComponent";
import SideNavComponent from "@/components/SideNavComponent";
import FooterComponent from "@/components/FooterComponent";
// import TrialExpireModal from "@/views/modals/TrialExpireModal";
import AppLoading from "@/components/AppLoading.vue";
import WelcomeModal from "@/components/welcome_popup/WelcomeModal.vue";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {TopBarComponent, SideNavComponent, WelcomeModal,
    FooterComponent, AppLoading},
  data() {
    return {
      welcomeShow: false,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      isLoadingCurrentPlan: 'plan/getIsLoadingCurrentPlan',
      user: 'auth/getUser',
      account: 'auth/getAccount',
      currentPlan: 'plan/getCurrentPlan',
    })
  },
  async created() {
    this.$store.dispatch('loadCountries');

    if (window.location.pathname !== this.baseUrl + 'reset') {
      if (this.user) {
        this.$store.commit('setLoadingApp', true);
        await this.axios.get('/user')
          .then(async (res) => {
            let user = res.data.data;
            user.token = this.$store.state.auth.user.token;
            
            this.$store.dispatch('auth/loadFeatures');
            this.$store.dispatch('adAccounts/loadAmazonMarketplaces');

            let params = new Proxy(new URLSearchParams(window.location.search), {
              get: (searchParams, prop) => searchParams.get(prop),
            });

            if (params.accountUser) {
              let account = user.accounts.find(a => a.id == params.accountUser);

              if (account) {
                this.$store.dispatch('auth/changeAccount', account);
              }
            }

            localStorage.setItem('user', JSON.stringify(user));
            this.$store.commit('auth/setUser', user);
            this.$store.dispatch('loadLabels');

            let reqs = [];
            reqs.push(this.$store.dispatch('plan/getCurrentPlan'));
            reqs.push(this.$store.dispatch('settings/loadSettings'));

            if (this.account.role === 1) {
               reqs.push(this.$store.dispatch('plan/getDefaultPaymentMethod'));
            } else {
              this.$store.dispatch('adAccounts/loadAmazonAccounts');

              this.$store.dispatch('adAccounts/loadGoogleAccounts');

              this.$store.dispatch('adAccounts/loadFbAccounts');
            }

            await Promise.all(reqs).then(response => {
              const currentPlanResponse = response[0];
              const settingsResponse = response[1];

              // Current Plan Response
              if (this.account.role === 1) {
                if (!currentPlanResponse.data.data || (currentPlanResponse.data.data.trial && !this.$store.state.plan.defaultPaymentMethod)) {
                  this.welcomeShow = true;
                  this.$refs.welcomeModal.show();
                }
              }

              // Settings Response
              let data = settingsResponse.data.data;

              if (!this.currentPlan || this.account.role === 1 && !data.amazon_user_id) {
                this.$router.push({name: 'settings'});
              }

              if (data.amazon_user_id && !data.is_setup_done && !this.welcomeShow && this.account.role === 1) {
                if (this.currentPlan.is_influencer)
                  this.$refs.welcomeModal.show(5);
                else
                  this.$refs.welcomeModal.show(4);
              }

              if (data.amazon_user_id)
                this.$store.dispatch('adAccounts/loadAmazonAccounts');

              if (data.google_user_id)
                this.$store.dispatch('adAccounts/loadGoogleAccounts');

              if (data.fb_user_id)
                this.$store.dispatch('adAccounts/loadFbAccounts');
            }).catch((res) => {
              if (this.account.role === 1) {
                this.$store.commit('plan/setPopupShowing', true);
                alert(res.message)

                return;
              }
            });
          })
          .catch(() => {
            this.redirectLogin();
          })

        this.$store.commit('setLoadingApp', false);
      } else {
        this.redirectLogin();
      }
    }

    this.$store.commit('settings/setLoadingApp', false);
  },
  async mounted() {
    if (this.user)
      this.subscribePusher();

    window.feather.replace();

    // Ad Blocker Check
    let message = "Please turn off adblock to continue"
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/show_ads.js';
    try {
      await fetch(new Request(googleAdUrl), {
        method: 'HEAD',
        mode: 'no-cors'
      }).catch(() => alert(message))
    } catch (e) {
      alert(message)
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'login' || this.$route.name === 'reset') {
        document.body.classList.add('account-body')
        document.body.classList.add('accountbg')
      } else {
        document.body.classList.remove('account-body')
        document.body.classList.remove('accountbg')
      }
    }
  },
  methods: {
    showWelcomeModal() {
      this.$refs.welcomeModal.show();
    },
    redirectLogin() {
      this.$store.commit('setLoadingApp', false);
      this.$store.commit('settings/setLoadingApp', false);

      let url = new URL(window.location.href);
      let paramInvitation = url.searchParams.get("invitation");
      let accountUser = url.searchParams.get("accountUser");
      let verified = url.searchParams.get("verified");
      let i_code = url.searchParams.get("i_code");

      let query = {};

      if (paramInvitation) {
        query.invitation = paramInvitation;
      }

      if (accountUser) {
        query.accountUser = accountUser;
      }

      if (i_code) {
        query.i_code = i_code;
      }

      if (verified) {
        query.verified = verified;
      }

      this.$store.dispatch('auth/resetUser');
      this.$router.push({name: 'login', query: query});
    },
    subscribePusher() {
      let self = this;

      this.pusher = new window.Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
      });

      let channel = this.pusher.subscribe(this.user.id + '');

      channel.bind('google', function(data) {
        if (data.message === "done") {
          self.$store.dispatch('settings/loadSettings');
          self.$store.dispatch('adAccounts/loadGoogleAccounts');
        }
      });
    }
  }
}
</script>


<style scoped>

.sidebar-theme {
  background: #181722;
  position: fixed;
  top: 0;
  height: 100%;
}

</style>